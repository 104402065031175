import schttp from 'public/src/services/schttp'
import { Toast as $toast } from '@shein/sui-mobile'
// GDPR注册时自动订阅
// export function bindSubscribe  (email, registerSource) {
//   $.post(gbCommonInfo.langPath + '/user/subscribe', {
//     email: email,
//     registerSource: registerSource || 0
//   }, function () {

//   })
// }

/**
 * 新的订阅接口
 * https://soapi.sheincorp.cn/application/423/routes/76909
 *
 * 邮箱/手机注册界面-订阅、
 * 手机号绑定/修改界面-订阅
 * 活动注册页 - 订阅
 * 
 * tips: 因账号需加密，因此前端传给中间层的账号明文字段改为alias，由中间层加密成subscribe_value
 * 
 * @param {*} options
*/
export function postAddSubscribe (options) {
  let retryCount = 1

  function requestAddSubscribe(options) {
    schttp({
      method: 'POST',
      url: '/api/auth/postAddSubscribe/update',
      data: options,
    })
      .catch(() => {
        if (!retryCount)return
        requestAddSubscribe(options)
        retryCount--
      })
  }

  requestAddSubscribe(options)
}
