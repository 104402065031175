import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'


export const reportSignupMarketing = (register_method = '') => {
  window?.TPM?.publish('signup', { 
    isNewUser: true,
    register_method,
    member_id: UserInfoManager.get({ key: 'memberId', actionType: 'reportSignupMarketing' }) || ''
  })
}

