import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { formatAccountChannel } from '@login/utils/loginAnalysis/formatData.js'
import analysisIndex from 'public/src/pages/login/analysis/index'
const { loginPageFrom } = analysisIndex

const metricCount = function({ name, tags = {}, message }){
  SIMetric.metricCount({
    metric_name: name,
    tags: {
      ...tags
    },
    message // 日志信息
  })
}

// const metricTime = function({ name, tags, value, config = {} }){
//   SIMetric.metricTime({
//     metric_name: name,
//     tags,
//     value
//   }, config)
// }


export function Metric_Login_Page_Expose({ page_type = '', sub_site = '' }){
  metricCount({
    name: 'web_login_first_total',
    tags: {
      login_from: loginPageFrom(),
      page_type,
      sub_site
    },
    message: 'Login page entrance exposure'
  })
}

export function Metric_Login_Continue_Click({ account_type = '', result = '', error = '', sub_site = '' }){
  metricCount({
    name: 'web_login_continue_total',
    tags: {
      login_from: loginPageFrom(),
      account_type,
      result,
      error,
      sub_site
    },
    message: 'Login continue click total'
  })
}

// export function Metric_Login_Continue_Click_Result({ account_type = '', result = '', error = '', sub_site = '' }){
//   metricCount({
//     name: 'web_login_continue_result_total',
//     tags: {
//       account_type,
//       result,
//       error,
//       sub_site
//     },
//     message: 'Login continue click result total'
//   })
// }

export function Metric_Login_Single_Click({ alias_type = '', is_remember = '', sub_site = '' }){
  metricCount({
    name: 'web_login_single_click_total',
    tags: {
      login_from: loginPageFrom(),
      account_type: formatAccountChannel(alias_type),
      is_remember,
      sub_site
    },
    message: 'Login single click total'
  })
}

// export function Metric_Login_Single_Result_Click({ account_type = '', result = '', error = '', sub_site = '' }){
//   metricCount({
//     name: 'web_login_single_click_result_total',
//     tags: {
//       account_type,
//       result,
//       error,
//       sub_site
//     },
//     message: 'Login single click result total'
//   })
// }


export function Metric_Login_Mutil_Click({ alias_type = '', is_remember = '', sub_site = '' }){
  metricCount({
    name: 'web_login_mutil_click_total',
    tags: {
      login_from: loginPageFrom(),
      account_type: formatAccountChannel(alias_type),
      is_remember,
      sub_site
    },
    message: 'Login mutil click total'
  })
}

// export function Metric_Login_Mutil_Result_Click({ account_type = '', result = '', error = '', sub_site = '' }){
//   metricCount({
//     name: 'web_login_mutil_click_result_total',
//     tags: {
//       account_type,
//       result,
//       error,
//       sub_site
//     },
//     message: 'Login mutil click result total'
//   })
// }

export function Metric_Login_Phone_Code({ result = '', type = '', sub_site = '', error = '' }){
  metricCount({
    name: 'web_login_phone_code_total',
    tags: {
      login_from: loginPageFrom(),
      result,
      error,
      type,
      sub_site
    },
    message: 'Login phone code total'
  })
}

export function Metric_Login_Click_Third_Total({ account_type = '', sub_site = '', isOneTap = false,  page_name = ''  }){
  metricCount({
    name: 'web_login_click_third_total',
    tags: {
      login_from: isOneTap ? page_name : loginPageFrom(),
      account_type,
      sub_site
    },
    message: 'Login click third total'
  })
}

export function Metric_Login_Click_Third_Result_Total({ account_type = '', result = '', error = '', is_register = '', sub_site = '', isOneTap = false,  page_name = '' }){
  metricCount({
    name: 'web_login_click_third_result_total',
    tags: {
      login_from: isOneTap ? page_name : loginPageFrom(),
      account_type,
      is_register,
      result,
      error,
      sub_site
    },
    message: 'Login click third result total'
  })
}

export function Metric_Login_Detail_Total({ account_type = '', sub_site = '' }){
  metricCount({
    name: 'web_login_detail_total',
    tags: {
      account_type,
      login_from: loginPageFrom(),
      sub_site
    },
    message: 'Login detail total'
  })
}

export function Metric_Register_Detail_Total({ account_type = '', sub_site = '' }){
  metricCount({
    name: 'web_register_detail_total',
    tags: {
      account_type,
      login_from: loginPageFrom(),
      sub_site
    },
    message: 'Register detail total'
  })
}

export function Metric_Login_Detail_Click_Total({ account_type = '', sub_site = '' }){
  metricCount({
    name: 'web_login_detail_click_total',
    tags: {
      account_type,
      login_from: loginPageFrom(),
      sub_site
    },
    message: 'Login detail click total'
  })
}

export function Metric_Register_Detail_Click_Total({ account_type = '', sub_site = '' }){
  metricCount({
    name: 'web_register_detail_click_total',
    tags: {
      account_type,
      login_from: loginPageFrom(),
      sub_site
    },
    message: 'Register detail click total'
  })
}

export function Metric_Login_Detail_Click_Result_Total({ account_type = '', result = '', error = '', sub_site = '' }){
  metricCount({
    name: 'web_login_detail_click_result_total',
    tags: {
      account_type,
      result,
      error,
      login_from: loginPageFrom(),
      sub_site
    },
    message: 'Login detail click result total'
  })
}

export function Metric_Register_Detail_Click_Result_Total({ account_type = '', result = '', error = '', sub_site = '' }){
  metricCount({
    name: 'web_register_detail_click_result_total',
    tags: {
      account_type,
      result,
      error,
      login_from: loginPageFrom(),
      sub_site
    },
    message: 'Register detail click result total'
  })
}


export function Metric_Quick_Register_Pop_Total({ login_from = '', sub_site = '' }){
  metricCount({
    name: 'web_quick_register_pop_total',
    tags: {
      login_from,
      sub_site
    },
    message: 'Quick register pop total'
  })
}

// export function Metric_Quick_Register_Click_Total({ login_from = '', sub_site = '' }){
//   metricCount({
//     name: 'web_quick_register_click_total',
//     tags: {
//       login_from,
//       sub_site
//     },
//     message: 'Quick register click total'
//   })
// }

export function Metric_Quick_Register_Click_Result_Total({ result = '', error = '', login_from = '', sub_site = '' }){
  metricCount({
    name: 'web_quick_register_click_result_total',
    tags: {
      result,
      error,
      login_from,
      sub_site
    },
    message: 'Quick register click result total'
  })
}



