import { InitFtoken } from 'public/src/pages/common/ftoken/index.js'
import schttp from 'public/src/services/schttp/index'


async function requestPromise(type, url, params, headers = {}) {
  const method = type.toLowerCase()
  const options = {
    method,
    url,
    headers: { 'x-ftoken': window?.gbCommonInfo?.ftoken, ...headers },
  }
  if (method == 'get') options.params = params
  else options.data = params

  const data = await schttp(options).catch((err) => ({ code: -1, msg: err.message }))

  return data
}

export async function request(type, url, params, headers) {
  await getFtoken()
  const data = await requestPromise(type, url, params, headers)
  return data
}

/**
 * 获取ftoken
 */
export function getFtoken () {
  return new Promise((resolve) => {
    if (window?.gbCommonInfo?.ftoken) return resolve(window.gbCommonInfo.ftoken)
    InitFtoken(() => {
      resolve(window.gbCommonInfo.ftoken)
    }, { notSendFtoken: true })
  })
}
