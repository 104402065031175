import Geetest from '../libs/geetest/sdk'
import CommonGeetest from '../libs/geetest/commonSdk'


function registerGeetestType(type) {
  return new Promise((resolve) => {
    window.UserGTInstance.register(() => resolve(), type)
  })
}

function reInitGeetestType() {
  return new Promise((resolve) => {
    window.UserGTInstance.reInitGt(() => resolve())
  })
}

function InitGeetest(common) {
  if (!window.UserGTInstance && common == false) {
    window.UserGTInstance = new Geetest()
    window.UserGTInstance.getGtAbtResult()
  } else if (!window.UserGTInstance && common) {
    window.UserGTInstance = new CommonGeetest()
    window.UserGTInstance.getGtAbtResult()
  }
}

export const geetestChallenge = async (apiService, apiParams, apiScene, common = false) => {
  await InitGeetest(common)
  if (!window.UserGTInstance) {
    InitGeetest(common)
  }
  const { status, challenge = '', risk = '' } = await window.UserGTInstance.verifyPromise()
  if (status == 'error' || status == 'close') {
    return { status }
  }
  const params = { challenge, gtRisk: risk }
  const apiResData = await apiService(Object.assign(apiParams, params))
  const errorCodes = ['402906', '402921', '402908']
  const { extend_info = {}, risk_id, risk_decision } = apiResData?.info || {}
  if (extend_info) {
    const { actions = [] } = extend_info
    const actionName = actions[0]?.name || ''
    if (errorCodes.includes(apiResData.code) && risk_decision == 0 && actionName != 'email_captcha' && actions.length == 1) {
      const geetestWays = {
        geetest_verification_icon: 'icon',
        geetest_verification_slide: 'slide'
      }
      if (['geetest_verification_slide', 'geetest_verification_icon'].includes(actionName)) {
        const isGeetest = true
        Object.assign(apiParams, { risk_id, risk_scene: apiScene, isGeetest })
        await registerGeetestType(geetestWays[actionName])
        return geetestChallenge(apiService, apiParams, apiScene, common)
      }
    }
  }
  if (apiResData.code == -403) {
    await reInitGeetestType()
    // challenge 为空，需要加载极验验证
    Object.assign(apiParams, { autoRetry: 1 })
    return geetestChallenge(apiService, apiParams, apiScene, common)
  }
  return { apiResData, status }
}
